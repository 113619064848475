'use strict';

$(window).on('load', function () {
    const styles = $('meta[name=defer-stylesheet]');
    styles.each(function () {
        const $this = $(this);
        const stylesheet = $('<link>', {
            rel: 'stylesheet',
            type: 'text/css',
            href: $this.attr('content')
        });
      $this.after(stylesheet);
    });
});
